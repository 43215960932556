import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Unscheduled Time", "key-receive-event": _vm.keyReceiveEvent }, on: { "change": _vm.onChangeControl } }, [_c("select-input", { key: "facilityId", attrs: { "label": "Facility", "placeholder": "Select Facility", "form-item": "", "rules": "required", "reference": "line-performance.common.local-facilities", "source": "id", "source-label": _vm.parseFacilityLabel, "value": _vm.facility.value, "span": 12, "disabled": true, "on-select-change": _vm.onFacilityChange } }), _c("select-input", { key: "areaId", attrs: { "label": "Department", "placeholder": "Select Department", "form-item": "", "rules": "required", "data-source": _vm.listAreas, "source": "id", "source-label": "name", "value": _vm.area.value, "span": 12, "on-select-change": _vm.onAreaChange } }), _c("select-input", { key: "productionLineID", attrs: { "label": "Line", "placeholder": "Select Line", "form-item": "", "rules": "required", "reference": "line-performance.common.local-production-lines", "custom-query": _vm.areaId ? "areaId=" + _vm.areaId : "", "source": "id", "source-label": "code", "span": 24 } }), _c("date-picker", { key: "startTimeLocal", attrs: { "label": "Start Time", "placeholder": "Select Start Time", "form-item": "", "format-date": "DD/MM/YYYY HH:mm", "show-time": "", "rules": "required", "span": 12 } }), _c("date-picker", { key: "endTimeLocal", attrs: { "label": "End Time", "placeholder": "Select End Time", "form-item": "", "format-date": "DD/MM/YYYY HH:mm", "show-time": "", "rules": "required", "span": 12 } }), _c("text-input", { key: "reason", attrs: { "form-item": "", "rules": "max:30|required", "label": "Reason", "placeholder": "Type Reason", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
var CreateUnscheduledDowntime_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "CreateUnscheduledDowntime",
  inject: ["crud", "facility", "area", "getListAreas"],
  data() {
    return {
      facilityId: this.facility.value,
      areaId: this.area.value,
      keyReceiveEvent: ["startTimeLocal", "endTimeLocal"]
    };
  },
  computed: {
    listAreas() {
      return this.getListAreas();
    }
  },
  created() {
    this.storeValue("facilityId", this.facility.value);
    this.storeValue("areaId", this.area.value);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    parseFacilityLabel(record) {
      if (record.description) {
        return record.name + " " + record.description;
      }
      return record.name;
    },
    onFacilityChange(value) {
      this.facilityId = value;
    },
    onAreaChange(value) {
      this.areaId = value;
    },
    onChangeControl(e) {
      if (e.startTimeLocal) {
        this.storeValue("startTime", this.$moment(e.startTimeLocal).utc().format("YYYY-MM-DD HH:mm"));
      }
      if (e.endTimeLocal) {
        this.storeValue("endTime", this.$moment(e.endTimeLocal).utc().format("YYYY-MM-DD HH:mm"));
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateUnscheduledDowntime = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-areas", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-production-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.unscheduleddowntimes", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/unscheduled-time" } }, [_c("create-unscheduled-downtime")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateUnscheduledDowntime
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
